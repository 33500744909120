import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Container,
  Flex,
  Link,
  Stack,
  VStack,
  Heading,
  Image,
  Center,
} from "@chakra-ui/react"
import theme from "../themes/theme";
import { useTranslation } from "react-i18next";
import FooterBig from "../components/FooterBig";
import Navbar from "../components/Navbar";
import { LangSet } from "../module/lang_set";
import protosongImg from '../img/product-thmbnail-1.jpg';


export const Products = () => {

  const { t } = useTranslation();
  LangSet();


  return (
<ChakraProvider resetCSS={false} theme={theme}>
  <VStack spacing={0}>
    
    <Navbar />

    <Center w={"100%"} >
      <Container as={Stack} maxW={'6xl'} p={5}>
        <VStack spacing={4} w={"100%"} >

        <Box h={"100"}></Box>

        <Box w={"100%"} >
          <Heading fontSize={"5xl"} my={"0"} py={"0"}>PRODUCTS</Heading>
        </Box>


        <Flex w={"100%"}>
          <Link href="./product_protosong">
            <Image src={protosongImg} w={"300px"} />
          </Link>
        </Flex>


        <Box h={"137"}></Box>

        </VStack>
      </Container>
    </Center>
    
    <FooterBig />
  </VStack>
</ChakraProvider>
  );
};
