import * as React from "react"
import "../styles/promo/style.scss";
import { LangSet } from "../module/lang_set";
import FooterBig from "../components/FooterBig";
import NavbarEasy from "../components/NavbarEasy";
import InstallProtosong from "../components/InstallProtosong";

export const Install = () => {
    LangSet();
    return (
        <React.Fragment>
            <NavbarEasy />
            <InstallProtosong />
            <FooterBig />
        </React.Fragment>
    )
}

