import { useLocation } from 'react-router-dom';
import { CurrencyDefAndsample } from "./currency_def_andsample";

export function CurrencySet(): string {
  const curArr = CurrencyDefAndsample();

  const search = useLocation().search;
  const q = new URLSearchParams(search);
  
  // パラメタで指定された場合
  if (!!q.get('curr') && (q.get('curr')! in curArr)) {
    const cur = q.get('curr') as string;
    window.localStorage.setItem('currency', cur);
    return cur;


  // 既に保存されている場合
  } else {
    var cur = window.localStorage.getItem('currency');
    if (!!cur && cur in curArr) {
      return cur;
    }
  }

  // 初めての場合はブラウザの言語設定から読み解く
  // イギリスは en-GB とか en-GB-oxendict 、インドは en-IN
  // ブラジルは pt-BR、インドネシアは id、パキスタンのパンジャブ語は pa
  var lang = (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language;
  var curr = "";
  if (lang.length >= 5) {
    if (lang.substring(2,5) == "-GB") {
      curr = "GBP";
    }
    if (lang.substring(2,5) == "-IN") {
      curr = "INR";
    }
    if (lang.substring(2,5) == "-BR") {
      curr = "BRL";
    }
  }
  if (curr == "" && lang.length >= 2) {
    var lang2 = lang.substring(0,2);
    if (lang2 == "ja") {
      curr = "JPY";
    }
    if (lang2 == "zh") {
      curr = "CNY";
    }
    if (lang2 == "en") {
      curr = "USD";
    }
    if (lang2 == "id") {
      curr = "IDR";
    }
    if (lang2 == "pa") {
      curr = "PKR";
    }
  }
  if (curr == "") curr = "EUR";

  window.localStorage.setItem('currency', curr);
  return curr;
}