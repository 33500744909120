import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    
    fonts: {
        body: `"Avenir","Microsoft Tai Le","Microsoft Sans Serif","Helvetica","YuGothic","Yu Gothic","游ゴシック体","STHeitiSC","Heiti SC","黒体-簡",sans-serif`,
        heading: `"Josefin Slab","Shippori Mincho","M PLUS Rounded 1c",serif`,
        serious: `"Lora", serif`,
    },

    colors: {
        moji: {
          main: "#362e2b",// 村井さんから
          dark: "#222222",// 村井さんから
          light: "#f5f5f5",// 村井さんから
        },
        solid: {
          main: "#DEDCDB",
        },
        brand: {
          main: "#4B95C2",
          mark: "#D8B189",
          back: "#f1ecdd",// 村井さんから
          backdark: "#222222",// 村井さんから
          backblue: "#1d1a45",// 村井さんから
          backblack: "#191919",// 村井さんから
        },
    },
    fontSizes: {
        xmini: '0.7em',
        mini: '0.8em',
        base: '16px',
        big: '1.2em',
    },
    borders: {
        main: "1px solid #DEDCDB"
    },
    styles: {
        global: {
            "html, body": {
                color: "moji.main",
                background: "brand.back",
                lineHeight: "1.4142135623",
                fontSize: "base",
                letterSpacing: "0.08em",
                textAlign: "justify",
                textJustify: "inter-ideograph",
                margin: 0,
            },
        }
    },
    components: {
        Link: {
            baseStyle: {
                color: "moji.dark",
                _hover: "textDecoration: none"
            }
        }
    }
});

export default theme;