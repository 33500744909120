import React from 'react';
import { useTranslation } from "react-i18next";

export default function FooterBig() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="footer">
        <div className="footer__inner">
          <div className="footer__logo">
            <img src={'/common/Andsample-logo-white.svg'} alt="andsample" />
          </div>
          <div className="footer__navi">
            <div className="footer__navi__item">
              <a href="/terms_of_service">{t("common.terms-of-service")}</a>
            </div>
            <div className="footer__navi__item">
              <a href="/privacy_policy">{t("common.privacy-policy")}</a>
            </div>
            <div className="footer__navi__item">
              <a href="/install">{t("common.download")}</a>
            </div>
            <div className="footer__navi__item">
              <a href="/reference">{t("common.commentary-video")}</a>
            </div>
            <div className="footer__navi__item">
              <a href="https://docs.google.com/forms/d/1b7Mmg6poY1Kq0SROk4S_wttMHb9vGYMwPEF4pMQHEeI/viewform?edit_requested=true">{t("common.contact")}</a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}