import { ColorModeScript } from "@chakra-ui/react"
import * as React from "react"
import ReactDOM from "react-dom"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Promo } from "./pages/promo"
import { Products } from "./pages/products"
import "./i18n/configs";
import "./styles/global.css";
import { PrivacyPolicy } from "./pages/privacy_policy"
import { TermsOfService } from "./pages/terms_of_service"
import { ProductProtosong } from "./pages/product_protosong"
import { Thankyou } from "./pages/thankyou"
import { Sorry } from "./pages/sorry"
import { Install } from "./pages/install"
import { Reference } from "./pages/reference"
import { ReleasenoteProtosong } from "./pages/releasenote_protosong"

// 注意
// .htaccessファイルに以下が必要。
// FallbackResource ./index.html
ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript />

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Promo />} />
        <Route path="/install" element={<Install />} />
        <Route path="/products" element={<Products />} />
        <Route path="/product_protosong" element={<ProductProtosong />} />
        <Route path="/releasenote_protosong" element={<ReleasenoteProtosong />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/reference" element={<Reference />} />
        <Route path="/terms_of_service" element={<TermsOfService />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/sorry" element={<Sorry />} />
      </Routes>
    </BrowserRouter>

    {/*  ここに余計な <App /> とかがあると、各ページでthemeが効かなくなります！！ */}
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
