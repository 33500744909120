import * as React from "react"
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import NavbarEasy from "../components/NavbarEasy";
import FooterBig from "../components/FooterBig";
import "../styles/termAndPolicy/style.scss";
import { useTranslation } from "react-i18next";
import { LangSet } from "../module/lang_set";
import i18n from "../i18n/configs";

export const PrivacyPolicy = () => {
  const { t } = useTranslation();
  LangSet();

  const search = useLocation().search;
  useEffect(() => {
    const cont_ja = document.querySelector(".ja");
    const cont_en = document.querySelector(".en");
    if (i18n.language == "ja") {
      if (cont_en) { cont_en.setAttribute("style", "display: none") };
      if (cont_ja) { cont_ja.removeAttribute("style") };
    } else {
      if (cont_en) { cont_en.removeAttribute("style") };
      if (cont_ja) { cont_ja.setAttribute("style", "display: none") };
    }
  })
  return (
    <React.Fragment>
      <NavbarEasy />
      <div className="container privacypolicy ja">
        <div className="page_header">
          <h1>Privacy Policy<span>プライバシーポリシー</span></h1>
        </div>
        <div className="page_content">
          <p>Andsampleは、個人情報の重要性を認識しその保護の徹底を図っています。ユーザー様への安心を提供するために個人情報の保護に関する法律および以下の方針を定め、個人情報の保護に努めています。なお、Andsampleは、法人様および団体のユーザー様に関する情報も個人情報と同様に取り扱っています。</p>
          <h2>個人情報の収集</h2>
          <p>Andsampleは、オンラインまたはイベント等で懸賞、アンケート等を実施する場合があり、回答以外にメールアドレスなどの個人情報の提供を必要とする場合があります。この場合には、必要に応じてこの個人情報を使用し参加者へ連絡をする場合がありますが、個人を特定できるような情報の開示は行いません。</p>
          <h2>個人情報の管理</h2>
          <p>Andsampleは、適切な個人情報の管理を行うとともに外部への流出防止に努めています。外部からの不正アクセスまたは紛失、破壊、改ざん等に対しては、適切な安全対策を実施し個人情報の保護に努めており、個人情報の利用目的が達成された場合には個人情報を消去致します。また、個人情報をユーザー様の同意なしに、業務委託先以外の第三者に開示/提供することはありません。ただし、法令により開示を求められた場合または警察等の公的機関から開示を求められた場合には、ユーザー様の同意なく個人情報を開示/提供する場合があります。</p>
          <h2>個人情報の利用</h2>
          <p>Andsampleは、ユーザー様の同意を得た場合、ユーザー様が必要とした情報を提供するため、この情報を使用して製品に関する情報等を関心を持つと考えるユーザー様ヘメール等で情報を送信する場合があります。</p>
          <h2>プライバシーポリシーの変更</h2>
          <p>Andsampleは、本プライバシーポリシーを変更しても個人情報に関するユーザー様の選択肢は変わりません。プライバシーポリシーは変更される可能性があるため、本プライバシーポリシーを定期的に確認して頂く必要があり、変更後に継続使用することを選択した場合には、新しいプライバシーポリシーに合意したものと見なされます。</p>
          <h2>プライバシーポリシーに関するお問い合わせ</h2>
          <p>AndsampleのプライバシーポリシーおよびWebサイトに関するご質問はお問い合わせフォームよりご連絡ください。</p>
          <h2>Cookieについて</h2>
          <p>ご利用者への利便性向上、最適なサイト表示をご提供する目的でCookieと呼ばれる情報をご利用者の端末に保存致しますが、ご利用者のプライバシーまたは利用環境を侵すことはありません。ご利用者がブラウザー設定などを変更することにより、Cookieの使用を禁止することも可能ですが、一部のコンテンツもしくは機能がご利用頂けなくなる場合がありますので予めご了承ください。</p>
          <h2>Google Analyticsについて</h2>
          <p>アクセスログの収集/解析にはGoogle Analyticsを使用しております。Google AnalyticsではCookieを使用し個人を特定する情報を含まずにログを収集します。なお、収集されるログはGoogle社のプライバシーポリシーに基づいて管理されます。Google Analyticsについて、およびGoogle社のプライバシーポリシーについては以下をご覧ください。</p>
          <p>
            <a href="https://www.google.com/intl/ja/analytics/">Google Analytics公式ウェブサイト</a>
            <a href="https://www.google.com/intl/ja/privacy/">Googleプライバシーセンター プライバシーポリシー</a>
          </p>
          <h2>オンライン広告でのターゲティングおよびリマーケティングについて</h2>
          <p>google、Yahoo!、Facebook、Instagram、Twitter、LINEを含む第三者配信事業者（以下、広告配信業者）により、インターネット上の様々なサイトに広告が掲載されています。これら広告配信業者は、Cookieを使用して、弊社サイトへの過去のアクセス情報に基づいて広告を配信します。利用者はこれら広告配信業者のオプトアウトページにアクセスし、Cookieの使用を無効にする事ができます。</p>
          <p>
            <a href="https://support.google.com/adwordspolicy/answer/143465?hl=ja">Google</a>
            <a href="http://docs.yahoo.co.jp/docs/info/terms/chapter1.html#cf2nd">Yahoo</a>
            <a href="https://www.facebook.com/help/109378269482053">Meta/Facebook</a>
            <a href="https://twitter.com/privacy?lang=ja">Twitter</a>
            <a href="https://line.me/ja/terms/policy/">LINE</a>
          </p>
          <hr />
          <p>2022年4月3日</p>
        </div>
      </div>
      <div className="container privacypolicy en">
        <div className="page_header">
          <h1>Privacy Policy<span>プライバシーポリシー</span></h1>
        </div>
        <div className="page_content">
          <p>Andsample recognize that privacy of your personal information is important. Here is information on what types of personal information we receive and collect when you use our apps and visit our website, and how we safeguard your information. We never sell your personal information to third parties. <br />
            Andsample handles information about corporate and group users in the same way as personal information.
          </p>
          <h2>Collection of personal information:</h2>
          <p>Andsample may conduct sweepstakes, questionnaires, etc. online or at events, and may require the provision of personal information such as e-mail addresses. <br />
            We may use this personal information to contact participants as necessary, but we will not disclose any personally identifiable information.
          </p>
          <h2>Management of personal information:</h2>
          <p>Andsample manages personal information appropriately and strives to prevent it from leaking to the outside. <br />
            We take appropriate safety measures to protect personal information against unauthorized access, loss, destruction, falsification, etc. from the outside, and when the purpose of use of personal information is achieved, personal information will be deleted.  <br />
            Personal information will not be disclosed or provided to any third party other than the outsourced company without the consent of the user. However, if disclosure is required by law or by a public institution such as the police, personal information may be disclosed or provided without the consent of the user.</p>
          <h2>Use of personal information:</h2>
          <p>Andsample may use this information to send information to users who may be interested in information about the product, etc., with the consent of the user.</p>
          <h2>About changes in privacy policy:</h2>
          <p>Andsample does not change the user's choice regarding personal information even if this privacy policy is changed. The privacy policy is subject to change, so you should review this privacy policy on a regular basis, and if you choose to continue using it after the change, you are agreeing to the new privacy policy.</p>
          <h2>Inquiries regarding privacy policy:</h2>
          <p>If you have any questions about Andsample's privacy policy or website, please contact us using the contact form.</p>
          <h2>About Cookie:</h2>
          <p>We store information called cookies on the user's terminal for the purpose of improving user convenience and providing optimal site display, but we do not invade the user's privacy or usage environment. It is possible for users to prohibit the use of cookies by changing their browser settings, etc., but please note that some content or functions may not be available.</p>
          <h2>About Google Analytics:</h2>
          <p>We use Google Analytics to collect and analyze access logs. Google Analytics uses cookies to collect logs that do not contain personally identifiable information. The collected logs are managed based on Google's privacy policy. For Google Analytics and Google's privacy/policy, please see below.</p>
          <p>
            <a href="https://www.google.com/intl/ja/analytics/">Google Analytics official website</a>
            <a href="https://www.google.com/intl/ja/privacy/">Google privacy center</a>
          </p>
          <h2>About targeting and remarketing in online advertising:</h2>
          <p>Advertisements are posted on various sites on the Internet by third-party distributors (hereinafter referred to as "advertisers") including google, Yahoo!, Facebook, Instagram, Twitter, and LINE. These advertising distributors use cookies to distribute advertisements based on past access information to our site. Users can access the opt-out pages of these ad distributors and disable the use of cookies.</p>
          <p>
            <a href="https://support.google.com/adwordspolicy/answer/143465?hl=ja">Google</a>
            <a href="http://docs.yahoo.co.jp/docs/info/terms/chapter1.html#cf2nd">Yahoo</a>
            <a href="https://www.facebook.com/help/109378269482053">Meta/Facebook</a>
            <a href="https://twitter.com/privacy?lang=ja">Twitter</a>
            <a href="https://line.me/ja/terms/policy/">LINE</a>
          </p>
          <hr />
          <p>2022年4月3日</p>
        </div>
      </div>
      <FooterBig />
    </React.Fragment>
  )
}