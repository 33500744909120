//import * as React from "react"
import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Heading,
  Link,
  VStack,
  Container,
  Stack,
  Center,
  Flex,
  Spacer,
  Image,
  Select,
  Button,
  TableContainer, Table, Tbody, Tr, Td,
} from "@chakra-ui/react"
import theme from "../themes/theme";
import protosongImg from '../img/product-detail-1.jpg';
import { CurrencySet } from "../module/currency_set";
import { CurrencyDefAndsample } from "../module/currency_def_andsample";
import NavbarEasy from './NavbarEasy';
import { t } from 'i18next';


type ProductDispSt = {
  os: string,
  lang: string,
}


const ProductProtosongChild: React.VFC<ProductDispSt> = ({ os, lang }) => {

  // 注意！正しい「product_id」をセットすること
  const url = "https://andsample.com/api/800_price_list.api?product_id=2";
  type Prices = {
    currency: string,
    price: string,
    tag: string,
    description: string,
  };
  var prices: Prices[] = [];


  const cur = CurrencySet();
  const [currencyState, setCurrencyState] = useState(cur);
  const [priceState, setPriceState] = useState("");
  const [tagState, setTagState] = useState("");

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value == currencyState) return;
    setCurrencyState(e.target.value);
  }

  const reflect = () => {
    const recs = prices.filter((rec) => rec.currency == currencyState);
    if (recs.length > 0){
      setPriceState(recs[0].price);
      setTagState(recs[0].tag);
    }
  };

  useEffect(() => {
    window.localStorage.setItem('currency', currencyState);
    if (prices.length < 1) {
      const priceReq = (): Promise<Prices[]> => fetch(url).then((x) => x.json());
      priceReq().then((recs) => {
        prices = recs;
        reflect();
      });
    } else {
      reflect();
    }
  }, [currencyState]);
  
  var curDef = CurrencyDefAndsample();
  var curDefKeys = Object.keys(curDef);

  return (
<ChakraProvider resetCSS={false} theme={theme}>
  <VStack spacing={0}>
    
    <NavbarEasy />

    
    <Center w={"100%"} >
      <Container as={Stack} maxW={'6xl'} p={5}>
        <VStack spacing={4} w={"100%"} >

        <Box h={"87"}></Box>

        <Flex w={"100%"}>
          <Box w={'full'}>
            <Image src={protosongImg} w={"100%"} />
          </Box>
          <Box px={5}>
            <Heading fontSize={"5xl"} fontFamily={"serious"} my={"0"} py={"0"}>Protosong</Heading>
            <Heading textAlign={"end"} fontSize={"3xl"} fontFamily={"serious"} my={"0"} py={"0"}>version 1</Heading>

            <Box h={"29"}></Box>

            <Flex w={"100%"} textAlign={"end"}>
              <Spacer />
              <Box>
                <Select value={currencyState} borderRadius={"3xl"} bgColor={"white"} onChange={onChange}>
                  {curDefKeys.map((oneCur) => (
                      <option value={oneCur}>{curDef[oneCur]}</option>
                  ))}
                </Select>
              </Box>
            </Flex>

            <Flex w={"100%"} textAlign={"start"}>
              <Text fontSize={"3xl"} fontFamily={"serif"}>{priceState}</Text>
            </Flex>

            <Box h={"3"}></Box>
            <TableContainer borderBottom={"0.5px solid"} borderColor={"moji.main"} minW={"330px"}>
              <Table>
                <Tbody>
                  <Tr borderTop={"0.5px solid"} borderColor={"moji.main"}>
                    <Td pl={0} pr={5} py={"15px"} borderBottom={"none"}><Text fontWeight={"bold"}>Mac</Text></Td>
                    <Td px={0} py={"15px"} borderBottom={"0.5px solid"} borderColor={"moji.main"}>OS</Td>
                    <Td px={0} py={"15px"} borderBottom={"0.5px solid"} borderColor={"moji.main"}>
                      10.14 Mojave<br />
                      10.15 Catalina<br />
                      11 Big Sur<br />
                      12 Monterey<br />
                      13 Ventura
                    </Td>
                  </Tr>
                  <Tr>
                    <Td pl={0} pr={5} py={"15px"} borderBottom={"0.5px solid"} borderColor={"moji.main"}></Td>
                    <Td px={0} py={"15px"} borderBottom={"0.5px solid"} borderColor={"moji.main"}>Model</Td>
                    <Td px={0} py={"15px"} borderBottom={"0.5px solid"} borderColor={"moji.main"}>
                      2015 Model~,<br />
                      M1 Model~
                    </Td>
                  </Tr>

                  <Tr borderTop={"0.5px solid"} borderColor={"moji.main"}>
                    <Td pl={0} pr={5} py={"15px"} borderBottom={"none"}><Text fontWeight={"bold"}>Windows</Text></Td>
                    <Td px={0} py={"15px"} borderBottom={"0.5px solid"} borderColor={"moji.main"}>OS</Td>
                    <Td px={0} py={"15px"} borderBottom={"0.5px solid"} borderColor={"moji.main"}>
                      Windows 7<br />
                      Windows 8.1<br />
                      Windows 10<br />
                      Windows 11
                    </Td>
                  </Tr>
                  <Tr>
                    <Td pl={0} pr={5} py={"15px"} borderBottom={"0.5px solid"} borderColor={"moji.main"}></Td>
                    <Td px={0} py={"15px"} borderBottom={"0.5px solid"} borderColor={"moji.main"}>CPU</Td>
                    <Td px={0} py={"15px"} borderBottom={"0.5px solid"} borderColor={"moji.main"}>
                      Intel 5th generation~
                    </Td>
                  </Tr>
                  
                  <Tr>
                    <Td px={0} py={"15px"}><Text fontWeight={"bold"}>{lang}</Text></Td>
                    <Td px={0} py={"15px"} colSpan={2}>English, 中文（简体）, 日本語</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>

            <Box h={"10"}></Box>
            {t("environment.sorryWindows")}

            <Box h={"10"}></Box>
            <Button border={'none'} size='lg' borderRadius={40}>
              <Text fontSize={"3xl"} fontFamily={"serious"} color={"moji.main"} onClick={() => {
                document.location.href = "https://andsample.com/store/020_checkout-session.html?tag=" + tagState;
              }}>BUY NOW</Text>
            </Button>
          </Box>
        </Flex>

        <Box h={"137"}></Box>

        </VStack>
      </Container>
    </Center>

  </VStack>
</ChakraProvider>
  );
};

export default ProductProtosongChild;