
import { useTranslation } from "react-i18next";
import { LangSet } from "../module/lang_set";
import ProductProtosongChild from '../components/product_protosong_child';

export const ProductProtosong = () => {

  // 言語切り替えがHookの無限ループを呼ぶので切り離した
  const { t } = useTranslation();
  LangSet();
  
  return (
<ProductProtosongChild os={t("environment.os")} lang={t("environment.lang")} />
  );
};
