import * as React from "react"

type EmbedYouTubePrm = {
    movieId: string,
}
  
  
const EmbedYouTube: React.VFC<EmbedYouTubePrm> = ({ movieId }) => {

    var srcSt = "https://www.youtube.com/embed/" + movieId + "?cc_load_policy=1";
    return (
        <React.Fragment>
            <iframe width="560" height="315" src={srcSt} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>           
        </React.Fragment>
    );
}

export default EmbedYouTube;