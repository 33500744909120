import * as React from "react"
import "../styles/promo/style.scss";
import { LangSet } from "../module/lang_set";
import Navbar from "../components/Navbar";
import FooterBig from "../components/FooterBig";
import PromoProtosong from "../components/PromoProtosong";

export const Promo = () => {
    LangSet();
    return (
        <React.Fragment>
            <Navbar />
            <PromoProtosong />
            <FooterBig />
        </React.Fragment>
    )
}

