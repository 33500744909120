import {
    ChakraProvider,
    Box,
    Text,
    Heading,
    VStack,
    Container,
    Stack,
    Center,
    Flex,
    TableContainer, Table, Tbody, Tr, Td,
  } from "@chakra-ui/react"
  import theme from "../themes/theme";
import { useTranslation } from "react-i18next";

export default function ReleasenoteProtosongChild() {
  const { t } = useTranslation();

  return (
  <ChakraProvider resetCSS={false} theme={theme}>
  <VStack spacing={0}>
    <Center w={"100%"} >
      <Container as={Stack} maxW={'6xl'} p={5}>
        <VStack spacing={4} w={"100%"} >

        <Box h={"87"}></Box>

        <Flex w={"100%"}>
          <Box px={5}>
            <Heading fontSize={"5xl"} fontFamily={"serious"} my={"0"} py={"0"}>{t("install.releasenote")}</Heading>

            <Box h={"99"}></Box>

            <TableContainer borderBottom={"0.5px solid"} borderColor={"moji.main"} minW={"240px"}>
              <Table>
                <Tbody>
                  <Tr borderTop={"0.5px solid"} borderColor={"moji.main"}>
                    <Td pr={20} py={10}><Text fontWeight={"bold"}>1.0.0</Text></Td>
                    <Td pr={20} py={10}><Text fontWeight={"bold"}>2022/8/6</Text></Td>
                    <Td pr={20} py={10}>
                      {t("releasenote.init")}
                    </Td>
                  </Tr>
                  <Tr borderTop={"0.5px solid"} borderColor={"moji.main"}>
                    <Td pr={20} py={10}><Text fontWeight={"bold"}>1.0.1</Text></Td>
                    <Td pr={20} py={10}><Text fontWeight={"bold"}>2022/9/4</Text></Td>
                    <Td pr={20} py={10}>
                      - {t("releasenote.con1.0.1-1")}<br />
                      - {t("releasenote.con1.0.1-2")}<br />
                      - {t("releasenote.con1.0.1-3")}<br />
                      - {t("releasenote.con1.0.1-4")}<br />
                      - {t("releasenote.con1.0.1-5")}<br />
                      - {t("releasenote.con1.0.1-6")}<br />
                      - {t("releasenote.con1.0.1-7")}<br />
                      - {t("releasenote.con1.0.1-8")}<br />
                      - {t("releasenote.con1.0.1-9")}<br />
                    </Td>
                  </Tr>
                  <Tr borderTop={"0.5px solid"} borderColor={"moji.main"}>
                    <Td pr={20} py={10}><Text fontWeight={"bold"}>1.0.2</Text></Td>
                    <Td pr={20} py={10}><Text fontWeight={"bold"}>2022/9/20</Text></Td>
                    <Td pr={20} py={10}>
                      - {t("releasenote.con1.0.2-1")}<br />
                      - {t("releasenote.con1.0.2-2")}<br />
                    </Td>
                  </Tr>

 
                </Tbody>
              </Table>
            </TableContainer>



          </Box>
        </Flex>

        </VStack>
      </Container>
    </Center>

  </VStack>
</ChakraProvider>
  );
}