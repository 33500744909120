import * as React from "react"
import "../styles/promo/style.scss";
import { LangSet } from "../module/lang_set";
import FooterBig from "../components/FooterBig";
import NavbarEasy from "../components/NavbarEasy";
import ReleasenoteProtosongChild from "../components/ReleasenoteProtosongChild";

export const ReleasenoteProtosong = () => {
    LangSet();
    return (
        <React.Fragment>
            <NavbarEasy />
            <ReleasenoteProtosongChild />
            <FooterBig />
        </React.Fragment>
    )
}