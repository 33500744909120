import * as React from "react"
import {useEffect, useState} from "react"
import {
    useLocation,
    } from 'react-router-dom';
export default function Navbar() {
    useEffect(() => {
    });
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const toggleOpen = () =>{
        setOpen(!open)
    }
    const path = location.pathname;
    return (
        <React.Fragment>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            <div className="header --pc">
                <div className="header__inner">
                    <div className="header__navi">
                        <div className="header__navi__item">
                            <a href="#product">Product</a>
                        </div>
                        <div className="header__navi__item">
                            <a href="#concept">Concept</a>
                        </div>
                    </div>
                    <div className="header__logo">
                        <img src={'./common/Andsample-logo.svg'} alt="andsample" />
                    </div>
                    <div className="header__navi">
                        <div className="header__navi__item">
                            <a href="#feature">Feature</a>
                        </div>
                        <div className="header__navi__item">
                            <a href="#download">Purchase</a>
                        </div>
                    </div>
                    <div className={"header__lang" + " " +(open ? "open" : "")}>
                        <button className="header__lang__nav" onClick={toggleOpen}>
                            Language<span className="material-symbols-outlined">arrow_drop_down</span>
                        </button>
                        <ul className="header__langSelect">
                            <li className="header__langSelectItem"><a href={path + "?lang=ja"}>日本語</a></li>
                            <li className="header__langSelectItem"><a href={path + "?lang=en"}>English</a></li>
                            <li className="header__langSelectItem"><a href={path + "?lang=zh"}>中文（简体）</a></li>
                         </ul>
                    </div>
                </div>
            </div>
            <div className="header --sp">
                <div className="header__inner">
                    <div className="header__logo">
                        <img src={'./common/Andsample-logo.svg'} alt="andsample" />
                    </div>
                    <div className="header__navi">
                        <div className="header__navi__item">
                            <a href="#product">Product</a>
                        </div>
                        <div className="header__navi__item">
                            <a href="#concept">Concept</a>
                        </div>
                        <div className="header__navi__item">
                            <a href="#feature">Feature</a>
                        </div>
                        <div className="header__navi__item">
                            <a href="#download">Purchase</a>
                        </div>
                    </div>
                    <div className={"header__lang" + " " +(open ? "open" : "")}>
                        <button className="header__lang__nav" onClick={toggleOpen}>
                            Language<span className="material-symbols-outlined">arrow_drop_down</span>
                        </button>
                        <ul className="header__langSelect">
                            <li className="header__langSelectItem"><a href={path + "?lang=ja"}>日本語</a></li>
                            <li className="header__langSelectItem"><a href={path + "?lang=en"}>English</a></li>
                            <li className="header__langSelectItem"><a href={path + "?lang=zh"}>中文（简体）</a></li>
                         </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}