import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Container,
  Center,
  Stack,
  Link,
  VStack,
  Code,
  Heading,
  Grid,
  Flex,
  Image,
} from "@chakra-ui/react"
import theme from "../themes/theme";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import FooterBig from "../components/FooterBig";
import { LangSet } from "../module/lang_set";
import NavbarEasy from "../components/NavbarEasy";




export const Thankyou = () => {
  
  const { t } = useTranslation();
  LangSet();

  const search = useLocation().search;
  const q = new URLSearchParams(search);

  var piCode: string = "(Sorry. The pi was not found.)";
  if (!!q.get('pi')) piCode = q.get('pi')!;
  var email: string = "(Sorry. The email was not found.)";
  if (!!q.get('em')) email = q.get('em')!;

  return (
<ChakraProvider resetCSS={false} theme={theme}>
  <VStack spacing={0}>
    
    <NavbarEasy />

    <Center w={"100%"} >
      <Container as={Stack} maxW={'6xl'} p={5}>
        <VStack spacing={4} w={"100%"} >

        <Box h={"61"}></Box>

        <Box w={"100%"} >
          <Heading fontSize={"5xl"} my={"0"} py={"0"}>{t("thankyou.head")}</Heading>
          <Box h={"34"}></Box>

          <Text>{t("thankyou.msg1")}</Text>
          <Text>{t("thankyou.msg2")}</Text>
          <Text>{t("thankyou.msg3")}</Text>
          <Box h={"23"}></Box>

          <Box border={"1px solid"} borderColor={"moji.main"} borderRadius={"7px"} p={3}>
            <Text fontSize={"xmini"}>pi code: {piCode}</Text>
            <Text fontSize={"xmini"}>email: {email}</Text>
          </Box>
        </Box>

        <Box h={"50"}></Box>
        <Text>{t("thankyou.dl")}</Text>

        <Box h={"47"}></Box>

        </VStack>

        <FooterBig />
      </Container>
    </Center>
    
  </VStack>
</ChakraProvider>
)
}