import * as React from "react"
import { useTranslation } from "react-i18next";
import EmbedYouTube from "./EmbedYouTube";

export default function ProtosongReference() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="reference">
        <main>
          <h1 className="reference__heading ttl-pc">Protosong 解説動画</h1>
          <div className="reference__movieItem" id="title-005">
            <h3 className="reference__subheading noborder">{t("reference.title-005")}</h3>
            <div className="reference__embedContainer">
              <EmbedYouTube movieId="4J7EyRkGld8"></EmbedYouTube>
            </div>
          </div>
          <div className="reference__movieItem" id="title-010">
            <h3 className="reference__subheading noborder">{t("reference.title-010")}</h3>
            <div className="reference__embedContainer">
              <EmbedYouTube movieId="hz3Hqd-sPLc"></EmbedYouTube>
            </div>
          </div>
          <div className="reference__movieItem" id="title-020">
            <h3 className="reference__subheading noborder">{t("reference.title-020")}</h3>
            <div className="reference__embedContainer">
              <EmbedYouTube movieId="qQy4Q26-Te4"></EmbedYouTube>
            </div>
          </div>
          <div className="reference__movieItem" id="title-030">
            <h3 className="reference__subheading noborder">{t("reference.title-030")}</h3>
            <div className="reference__embedContainer">
              <EmbedYouTube movieId="2dumAcbWdVs"></EmbedYouTube>
            </div>
          </div>
          <div className="reference__movieItem" id="title-040">
            <h3 className="reference__subheading noborder">{t("reference.title-040")}</h3>
            <div className="reference__embedContainer">
              <EmbedYouTube movieId="cH-TyjbfqpA"></EmbedYouTube>
            </div>
          </div>
          <div className="reference__movieItem" id="title-210">
            <h3 className="reference__subheading noborder">{t("reference.title-210")}</h3>
            <div className="reference__embedContainer">
              <EmbedYouTube movieId="TSeY-oEKlIM"></EmbedYouTube>
            </div>
          </div>
          <div className="reference__movieItem" id="title-220">
            <h3 className="reference__subheading noborder">{t("reference.title-220")}</h3>
            <div className="reference__embedContainer">
              <EmbedYouTube movieId="Y70ncNRnZyg"></EmbedYouTube>
            </div>
          </div>
          <div className="reference__movieItem" id="title-230">
            <h3 className="reference__subheading noborder">{t("reference.title-230")}</h3>
            <div className="reference__embedContainer">
              <EmbedYouTube movieId="Lxzj7LHVHY4"></EmbedYouTube>
            </div>
          </div>
          <div className="reference__movieItem" id="title-310">
            <h3 className="reference__subheading noborder">{t("reference.title-310")}</h3>
            <div className="reference__embedContainer">
              <EmbedYouTube movieId="pDXwe9sayQg"></EmbedYouTube>
            </div>
          </div>
          <p className="reference__footnote">{t("reference.addingMessage")}</p>
          <p className="reference__footnote">{t("thankyou.msg3")}</p>
        </main>
        <aside className="reference__index">
          <h1 className="reference__heading ttl-sp">Protosong 解説動画</h1>
          <h2 className="reference__heading">Index</h2>
          <h3 className="reference__subheading">{t("reference.title-cat1")}</h3>
          <ul className="reference__indexList">
            <li className="reference__indexListItem"><a href="#title-005">{t("reference.title-005")}</a></li>
            <li className="reference__indexListItem"><a href="#title-010">{t("reference.title-010")}</a></li>
            <li className="reference__indexListItem"><a href="#title-020">{t("reference.title-020")}</a></li>
            <li className="reference__indexListItem"><a href="#title-030">{t("reference.title-030")}</a></li>
            <li className="reference__indexListItem"><a href="#title-040">{t("reference.title-040")}</a></li>
          </ul>
          <h3 className="reference__subheading">{t("reference.title-cat2")}</h3>
          <ul className="reference__indexList">
            <li className="reference__indexListItem"><a href="#title-210">{t("reference.title-210")}</a></li>
            <li className="reference__indexListItem"><a href="#title-220">{t("reference.title-220")}</a></li>
            <li className="reference__indexListItem"><a href="#title-230">{t("reference.title-230")}</a></li>
            <li className="reference__indexListItem"><a href="#title-310">{t("reference.title-310")}</a></li>
          </ul>
        </aside>
      </div>
    </React.Fragment>
    //   <ChakraProvider resetCSS={false} theme={theme}>
    //   <VStack spacing={0}>
    //     <Center w={"100%"} >
    //       <Container as={Stack} maxW={'6xl'} p={5}>
    //         <VStack spacing={4} w={"100%"} >

    //         <Box h={"87"}></Box>

    //         <Flex w={"100%"}>
    //           <Box px={5}>
    //           <Heading fontSize={"5xl"} fontFamily={"serious"} my={"0"} py={"0"}>{t("reference.title")}</Heading>

    //           <Box h={"59"}></Box>
    //           <Heading fontSize={"3xl"} fontFamily={"serious"} my={"0"} py={"0"}>Index :</Heading>

    //           <Heading fontSize={"2xl"} fontFamily={"serious"} my={"0"} py={"0"}>&lt;&lt;{t("reference.title-cat1")}&gt;&gt;</Heading>
    //           <Link href="#title-005">005 {t("reference.title-005")}</Link><br />
    //           <Link href="#title-010">010 {t("reference.title-010")}</Link><br />
    //           <Link href="#title-020">020 {t("reference.title-020")}</Link><br />
    //           <Link href="#title-030">030 {t("reference.title-030")}</Link><br />
    //           <Link href="#title-040">040 {t("reference.title-040")}</Link><br />

    //           <Heading fontSize={"2xl"} fontFamily={"serious"} my={"0"} py={"0"}>&lt;&lt;{t("reference.title-cat2")}&gt;&gt;</Heading>
    //           <Link href="#title-210">210 {t("reference.title-210")}</Link><br />
    //           <Link href="#title-220">220 {t("reference.title-220")}</Link><br />
    //           <Link href="#title-230">230 {t("reference.title-230")}</Link><br />
    //           <Link href="#title-310">310 {t("reference.title-310")}</Link><br />


    //           <Box h={"59"}></Box>
    //           <Heading id={"title-005"} fontSize={"3xl"} fontFamily={"serious"} my={"0"} py={"0"}>005 {t("reference.title-005")}</Heading>
    //           <EmbedYouTube movieId="4J7EyRkGld8"></EmbedYouTube>

    //           <Box h={"59"}></Box>
    //           <Heading id={"title-010"} fontSize={"3xl"} fontFamily={"serious"} my={"0"} py={"0"}>010 {t("reference.title-010")}</Heading>
    //           <EmbedYouTube movieId="hz3Hqd-sPLc"></EmbedYouTube>

    //           <Box h={"59"}></Box>
    //           <Heading id={"title-020"} fontSize={"3xl"} fontFamily={"serious"} my={"0"} py={"0"}>020 {t("reference.title-020")}</Heading>
    //           <EmbedYouTube movieId="qQy4Q26-Te4"></EmbedYouTube>

    //           <Box h={"59"}></Box>
    //           <Heading id={"title-030"} fontSize={"3xl"} fontFamily={"serious"} my={"0"} py={"0"}>030 {t("reference.title-030")}</Heading>
    //           <EmbedYouTube movieId="2dumAcbWdVs"></EmbedYouTube>

    //           <Box h={"59"}></Box>
    //           <Heading id={"title-040"} fontSize={"3xl"} fontFamily={"serious"} my={"0"} py={"0"}>040 {t("reference.title-040")}</Heading>
    //           <EmbedYouTube movieId="cH-TyjbfqpA"></EmbedYouTube>





    //           <Box h={"59"}></Box>
    //           <Heading id={"title-210"} fontSize={"3xl"} fontFamily={"serious"} my={"0"} py={"0"}>210 {t("reference.title-210")}</Heading>
    //           <EmbedYouTube movieId="TSeY-oEKlIM"></EmbedYouTube>

    //           <Box h={"59"}></Box>
    //           <Heading id={"title-220"} fontSize={"3xl"} fontFamily={"serious"} my={"0"} py={"0"}>220 {t("reference.title-220")}</Heading>
    //           <EmbedYouTube movieId="Y70ncNRnZyg"></EmbedYouTube>

    //           <Box h={"59"}></Box>
    //           <Heading id={"title-230"} fontSize={"3xl"} fontFamily={"serious"} my={"0"} py={"0"}>230 {t("reference.title-230")}</Heading>
    //           <EmbedYouTube movieId="Lxzj7LHVHY4"></EmbedYouTube>

    //           <Box h={"59"}></Box>
    //           <Heading id={"title-310"} fontSize={"3xl"} fontFamily={"serious"} my={"0"} py={"0"}>310 {t("reference.title-310")}</Heading>
    //           <EmbedYouTube movieId="pDXwe9sayQg"></EmbedYouTube>



    //           <Box h={"111"}></Box>

    //           <Flex w={"100%"}>
    //             <a href="/product_protosong">{t("reference.addingMessage")}</a>
    //           </Flex>




    //           </Box>
    //         </Flex>

    //         <Box h={"100"}></Box>
    //         <Text>{t("thankyou.msg3")}</Text>
    //         <Box h={"37"}></Box>

    //         </VStack>
    //       </Container>
    //     </Center>

    //   </VStack>
    // </ChakraProvider>
  );
}