import {
    ChakraProvider,
    Box,
    Text,
    Heading,
    VStack,
    Container,
    Stack,
    Center,
    Flex,
    TableContainer, Table, Tbody, Tr, Td,
  } from "@chakra-ui/react"
  import theme from "../themes/theme";
import { useTranslation } from "react-i18next";

export default function InstallProtosong() {
  const { t } = useTranslation();

  return (
  <ChakraProvider resetCSS={false} theme={theme}>
  <VStack spacing={0}>
    <Center w={"100%"} >
      <Container as={Stack} maxW={'6xl'} p={5}>
        <VStack spacing={4} w={"100%"} >

        <Box h={"87"}></Box>

        <Flex w={"100%"}>
          <Box px={5}>
            <Heading fontSize={"5xl"} fontFamily={"serious"} my={"0"} py={"0"}>{t("install.title")}</Heading>

            <Box h={"29"}></Box>

            <Flex w={"100%"}>
              <a href="/product_protosong">{t("install.buy")}</a>
            </Flex>

            <Flex w={"100%"}>
              <a href="/releasenote_protosong">{t("install.releasenote")}</a>
            </Flex>

            <Box h={"63"}></Box>




            <Flex w={"100%"} textAlign={"start"}>
              <Text fontSize={"5xl"} fontFamily={"serif"}>Mac</Text>
            </Flex>
            {t("install.recommendedEnv")} : Intel Model 2015~, M1 Model~<br />
            OS 10.14 Mojave<br />
            OS 10.15 Catalina<br />
            OS 11 Big Sur<br />
            OS 12 Monterey<br />
            OS 13 Ventura
            <Box h={"7"}></Box>
            <TableContainer borderBottom={"0.5px solid"} borderColor={"moji.main"} minW={"240px"}>
              <Table>
                <Tbody>
                  <Tr borderTop={"0.5px solid"} borderColor={"moji.main"}>
                    <Td pr={20} py={10}><Text fontWeight={"bold"}><a href="/dl/Protosong_0.dmg">Protosong_0.dmg</a></Text></Td>
                    <Td pr={20} py={10}>
                      Version 0 (beta) {t("install.thisIsInstaller")} 
                    </Td>
                  </Tr>
                  <Tr borderTop={"0.5px solid"} borderColor={"moji.main"}>
                    <Td pr={20} py={10}><Text fontWeight={"bold"}><a href="/dl/Protosong_1.dmg">Protosong_1.dmg</a></Text></Td>
                    <Td pr={20} py={10}>
                      Version 1 {t("install.thisIsInstaller")} 
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>



            <Box h={"99"}></Box>


            <Flex w={"100%"} textAlign={"start"}>
              <Text fontSize={"5xl"} fontFamily={"serif"}>Windows</Text>
            </Flex>
            {t("install.recommendedEnv")} : Intel 5th generation~<br />
            Windows 7<br />
            Windows 8.1<br />
            Windows 10<br />
            Windows 11
            <Box h={"7"}></Box>
            <TableContainer borderBottom={"0.5px solid"} borderColor={"moji.main"} minW={"240px"}>
              <Table>
                <Tbody>
                  <Tr borderTop={"0.5px solid"} borderColor={"moji.main"}>
                    <Td pr={20} py={10}><Text fontWeight={"bold"}><a href="https://dotnet.microsoft.com/ja-jp/download/dotnet/3.1">
                        .NetCore SDK 3.1.420 (official)
                    </a></Text></Td>
                    <Td pr={20} py={10}>
                        {t("install.isNecessary")}<br />
                        {t("install.cacheGuide")}<br />
                        <a href="https://andsample.com/dl/dotnet-sdk-3.1.420-win-x64.exe" download>.NetCore SDK 3.1.420 (cache)</a>
                    </Td>
                  </Tr>
                  <Tr borderTop={"0.5px solid"} borderColor={"moji.main"}>
                    <Td pr={20} py={10}><Text fontWeight={"bold"}><a href="https://docs.microsoft.com/ja-jp/cpp/windows/latest-supported-vc-redist?view=msvc-160">
                        VC 2015-2022<br />Redistributable Package (official)
                        </a></Text></Td>
                    <Td pr={20} py={10}>
                        {t("install.isNecessary")}<br />
                        {t("install.cacheGuide")}<br />
                        <a href="https://andsample.com/dl/VC_redist.x64.exe" download>VC 2015-2022 Redistributable Package (cache)</a>
                    </Td>
                  </Tr>
                  <Tr borderTop={"0.5px solid"} borderColor={"moji.main"}>
                    <Td pr={20} py={10}><Text fontWeight={"bold"}><a href="https://www.catalog.update.microsoft.com/Search.aspx?q=KB4457144">Hotfix KB4457144 (official)</a></Text></Td>
                    <Td pr={20} py={10}>
                        {t("install.isNecessaryOnWin7")}<br />
                        {t("install.cacheGuide")}<br />
                        <a href="https://andsample.com/dl/windows6.1-kb4457144-x64_5ca467d42deadc2b2f4010c4a26b4a6903790dd5.msu" download>Hotfix KB4457144 (cache)</a>
                    </Td>
                  </Tr>
                  <Tr borderTop={"0.5px solid"} borderColor={"moji.main"}>
                    <Td pr={20} py={10}><Text fontWeight={"bold"}><a href="https://dotnet.microsoft.com/ja-jp/download/dotnet-framework/net472">.Net Framewolk 4.7.2（official）</a></Text></Td>
                    <Td pr={20} py={10}>
                        {t("install.mayBeNecessaryOn8OrLater")}<br />
                        {t("install.cacheGuide")}<br />
                        <a href="https://andsample.com/dl/ndp472-kb4054531-web.exe" download>.Net Framewolk 4.7.2 (cache)</a>
                    </Td>
                  </Tr>
                  <Tr borderTop={"0.5px solid"} borderColor={"moji.main"}>
                    <Td pr={20} py={10}><Text fontWeight={"bold"}><a href="https://andsample.com/dl/Protosong_0.msi" download>Protosong_0.msi</a></Text></Td>
                    <Td pr={20} py={10}>
                        {t("install.thisIsInstaller0")}<br />
                        {t("install.alternativeZip")}<br />
                        <a href="https://andsample.com/dl/Protosong_0.zip" download>Protosong_0.zip</a>
                    </Td>
                  </Tr>
                  <Tr borderTop={"0.5px solid"} borderColor={"moji.main"}>
                    <Td pr={20} py={10}><Text fontWeight={"bold"}><a href="https://andsample.com/dl/Protosong_1.msi" download>Protosong_1.msi</a></Text></Td>
                    <Td pr={20} py={10}>
                        {t("install.thisIsInstaller1")}<br />
                        {t("install.alternativeZip")}<br />
                        <a href="https://andsample.com/dl/Protosong_1.zip" download>Protosong_1.zip</a>
                    </Td>
                  </Tr>

 
                </Tbody>
              </Table>
            </TableContainer>



          </Box>
        </Flex>

        <Box h={"100"}></Box>
        <Text>{t("thankyou.msg3")}</Text>
        <Box h={"37"}></Box>

        </VStack>
      </Container>
    </Center>

  </VStack>
</ChakraProvider>
  );
}