import { useTranslation } from "react-i18next";

export function CurrencyDefAndsample(): {[key: string]: string} {
  const { t } = useTranslation();

  const curArr: {[key: string]: string} = {
    "JPY": t("currency.jpy"),
    "USD": t("currency.usd"),
    "EUR": t("currency.eur"),
    "GBP": t("currency.gbp"),
    "CNY": t("currency.cny"),
    "PKR": t("currency.pkr"),
    "IDR": t("currency.idr"),
    "BRL": t("currency.brl"),
    "INR": t("currency.inr"),
  };
  return curArr;
}