import * as React from "react"
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { TRUE } from "sass";


export default function PromoProtosong() {
    const { t } = useTranslation();
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        const wrapCharSpan = function (str:any) {
            return [...str].map(char => `<span>${char}</span>`).join('');
        }
    
        const target = document.querySelectorAll(".FV__copy__group");
        if(target){
            target.forEach((item: any) => {
                item.innerHTML = wrapCharSpan(item.textContent);
            })
        }
        setAnimation()
    }, [])
    const setAnimation = () => {
        const hoverItems = gsap.utils.toArray('.js-hover');
        hoverItems.forEach((item: any) => {
            gsap.fromTo(item,
                {
                    y: 40,
                    autoAlpha: 0
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 1.2,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: item.parentNode,
                        start: 'top center'
                    }
                }
            );
        });
        // const mainCopy = gsap.utils.toArray('.FV__copy__group > span');
        // mainCopy.forEach((item: any) => {
        //     gsap.fromTo(item,
        //     {
        //         y: 60,
        //         autoAlpha: 0
        //     },
        //     {
        //         y: 0,
        //         autoAlpha: 1,
        //         duration: 1.2,
        //         ease: 'power2.in',
        //         scrollTrigger: {
        //             trigger: item.parentNode,
        //             start: 'top center'
        //         },
        //         stagger: {
        //             from: "start", 
        //             amount: 1 
        //         }
        //     }
        // );
        // })
        gsap.fromTo('.FV__copy__group > span',
                {
                    y: 100,
                    autoAlpha: 1
                },
                {
                    y: -2,
                    autoAlpha: 1,
                    duration: 1.2,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: '.FV__copy__group > span',
                        start: 'top center'
                    },
                    stagger: {
                        from: "start", 
                        amount: 0.5 
                    }
                }
        );
    }
    return (
        <React.Fragment>
            <div className="FV">
                <h1 className="FV__copy">
                    <div className="FV__copy">
                        <span className="FV__copy__group">Enjoy</span>
                        <span className="FV__copy__group">experimenting</span>
                        <span className="FV__copy__group">with</span>
                        <span className="FV__copy__group">chord</span>
                        <span className="FV__copy__group">progressions!</span>
                    </div>
                    <p className="FV__copy--ja">
                        {t("promo.title")}
                    </p>
                </h1>
            </div>
            <div className="products" id="product">
                <div className="gridBase--8-4">
                    <div className="imageWrapper n1 js-hover">
                        <div className="products__image">
                            <img src={'./promo-img/product-1.jpg'} alt="andsample protosong" />
                        </div>
                    </div>
                </div>
                <div className="gridBase--8-4">
                    <div className="imageWrapper n2 js-hover">
                        <div className="products__image">
                            <img src={'./promo-img/product-2.jpg'} alt="andsample protosong" />
                        </div>
                    </div>
                </div>
                <div className="gridBase--8-4">
                    <div className="imageWrapper n3">
                        <div className="products__image js-hover">
                            <img src={'./promo-img/product-3.jpg'} alt="andsample protosong" />
                        </div>
                        <div className="products__text">
                            <h1 className="products__title js-hover">
                                <p className="products__title__upper">{t("promo.subtitle")}</p>
                                {t("promo.product")}
                            </h1>
                            <p className="products__lead js-hover">
                                {t("promo.pros-big-title")}
                            </p>
                            <p className="products__body js-hover">
                                {t("promo.pros-big-sec1")}
                                <br />
                                {t("promo.pros-big-sec2")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="concept" id="concept">
                <div className="concept__inner">
                    <h2 className="concept__title js-hover">
                        <p className="concept__title__upper">{t("promo.pros-middle-title")}</p>
                        Concept
                    </h2>
                    <ul className="concept__list">
                        <li className="concept__list__item js-hover">
                            <h3 className="concept__subtitle">
                                <span className="concept__subtitle__num">01</span>
                                {t("promo.pros-1-head")}
                            </h3>
                            <p className="concept__body">{t("promo.pros-1-body")}</p>
                        </li>
                        <li className="concept__list__item js-hover">
                            <h3 className="concept__subtitle">
                                <span className="concept__subtitle__num">02</span>
                                {t("promo.pros-2-head")}
                            </h3>
                            <p className="concept__body">{t("promo.pros-2-body")}</p>
                        </li>
                        <li className="concept__list__item js-hover">
                            <h3 className="concept__subtitle">
                                <span className="concept__subtitle__num">03</span>
                                {t("promo.pros-3-head")}
                            </h3>
                            <p className="concept__body">
                                {t("promo.pros-3-body")}
                            </p>
                        </li>
                        <li className="concept__list__item js-hover">
                            <h3 className="concept__subtitle">
                                <span className="concept__subtitle__num">04</span>
                                {t("promo.pros-4-head")}
                            </h3>
                            <p className="concept__body">{t("promo.pros-4-body")}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="feature" id="feature">
                <div className="feature__innner">
                    <h2 className="feature__title js-hover">
                        <p className="feature__title__upper">{t("promo.emo-big-title")}</p>
                        Feature
                    </h2>
                    <div className="feature__box">
                        <div className="feature__img js-hover">
                            <img src="./promo-img/emo01.jpg" alt="" />
                        </div>
                        <div className="feature__text">
                            <h3 className="feature__subtitle js-hover">{t("promo.emo-01-title")}</h3>
                            <p className="feature__body js-hover">
                                {t("promo.emo-01-sec1")}<br />
                                {t("promo.emo-01-sec2")}<br />
                                {t("promo.emo-01-sec3")}<br />
                                {t("promo.emo-01-sec4")}<br />
                                {t("promo.emo-01-sec5")}<br />
                                {t("promo.emo-01-sec6")}
                            </p>
                        </div>
                    </div>
                    <div className="feature__box">
                        <div className="feature__img js-hover">
                            <img src="./promo-img/emo02.jpg" alt="" />
                        </div>
                        <div className="feature__text">
                            <h3 className="feature__subtitle js-hover">{t("promo.emo-02-title")}</h3>
                            <p className="feature__body js-hover">
                                {t("promo.emo-02-sec1")}
                            </p>
                        </div>
                    </div>
                    <div className="feature__box">
                        <div className="feature__img js-hover">
                            <img src="./promo-img/emo03.jpg" alt="" />
                        </div>
                        <div className="feature__text">
                            <h3 className="feature__subtitle js-hover">{t("promo.emo-03-title")}</h3>
                            <p className="feature__body js-hover">
                                {t("promo.emo-03-sec1")}<br />
                                {t("promo.emo-03-sec2")}<br />
                                {t("promo.emo-03-sec3")}
                            </p>
                        </div>
                    </div>
                    <div className="feature__box">
                        <div className="feature__img js-hover">
                            <img src="./promo-img/emo04.jpg" alt="" />
                        </div>
                        <div className="feature__text">
                            <h3 className="feature__subtitle">{t("promo.emo-04-title")}</h3>
                            <p className="feature__body">
                                {t("promo.emo-04-sec1")}<br />
                                {t("promo.emo-04-sec2")}
                            </p>
                        </div>
                    </div>
                    <div className="feature__box">
                        <div className="feature__img js-hover">
                            <img src="./promo-img/emo05.jpg" alt="" />
                        </div>
                        <div className="feature__text">
                            <h3 className="feature__subtitle js-hover">{t("promo.emo-05-title")}</h3>
                            <p className="feature__body js-hover">
                                {t("promo.emo-05-sec1")}<br />
                                {t("promo.emo-05-sec2")}
                            </p>
                        </div>
                    </div>
                    <div className="feature__box">
                        <div className="feature__img js-hover">
                            <img src="./promo-img/emo06.jpg" alt="" />
                        </div>
                        <div className="feature__text">
                            <h3 className="feature__subtitle js-hover">{t("promo.emo-06-title")}</h3>
                            <p className="feature__body js-hover">
                                {t("promo.emo-06-sec1")}<br />
                                {t("promo.emo-06-sec2")}
                            </p>
                        </div>
                    </div>
                    <div className="feature__box">
                        <div className="feature__img js-hover">
                            <img src="./promo-img/emo07.jpg" alt="" />
                        </div>
                        <div className="feature__text">
                            <h3 className="feature__subtitle js-hover">{t("promo.emo-07-title")}</h3>
                            <p className="feature__body js-hover">
                                {t("promo.emo-07-sec1")}
                            </p>
                        </div>
                    </div>
                    <div className="feature__box">
                        <div className="feature__img js-hover">
                            <img src="./promo-img/emo08.jpg" alt="" />
                        </div>
                        <div className="feature__text">
                            <h3 className="feature__subtitle js-hover">{t("promo.emo-08-title")}</h3>
                            <p className="feature__body js-hover">
                                {t("promo.emo-08-sec1")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="download" id="download">
                <a href="./product_protosong">
                    <div className="download__title js-hover">Purchase Now.</div>
                    <div className="download__img js-hover">
                        <img src="./promo-img/protosong-icon@256.png" alt="" />
                    </div>
                    <div className="download__name js-hover">
                        Protosong version 1
                    </div>
                </a>
            </div>

        </React.Fragment>
    )
}