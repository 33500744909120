import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Container,
  Center,
  Stack,
  Link,
  VStack,
  Code,
  Heading,
  Grid,
  Flex,
  Image,
} from "@chakra-ui/react"
import theme from "../themes/theme";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import FooterBig from "../components/FooterBig";
import Navbar from "../components/Navbar";
import { LangSet } from "../module/lang_set";




export const Sorry = () => {
  
  const { t } = useTranslation();
  LangSet();

  return (
<ChakraProvider resetCSS={false} theme={theme}>
  <VStack spacing={0}>
    
    <Navbar />

    <Center w={"100%"} >
      <Container as={Stack} maxW={'6xl'} p={5}>
        <VStack spacing={4} w={"100%"} >

        <Box h={"100"}></Box>

        <Box w={"100%"} >
          <Heading fontSize={"5xl"} my={"0"} py={"0"}>{t("sorry.head")}</Heading>
          <Text>{t("sorry.msg1")}</Text>
          <Text>{t("sorry.msg2")}</Text>
        </Box>

        <Box h={"137"}></Box>

        </VStack>
      </Container>
    </Center>
    
    <FooterBig />
  </VStack>
</ChakraProvider>
)
}