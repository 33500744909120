import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import i18n from "i18next";

export function LangSet() {
  const search = useLocation().search;
  useEffect(() => {
    
    const q = new URLSearchParams(search);

    if (!!q.get('lang') && (q.get('lang') == 'ja' || q.get('lang') == 'en' || q.get('lang') == 'zh')) {
      i18n.changeLanguage(q.get('lang') as string)
      window.localStorage.setItem('lang', q.get('lang') as string );
    } else {
      var lang = window.localStorage.getItem('lang');
      if (lang != "ja" && lang != "en" && lang != "zh") {
        lang = (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language;
        //console.log("GOT Brouser lang=" + lang);
  
        if (lang.length > 2) lang = lang.substring(0,2);
        if (lang != "ja" && lang != "en" && lang != "zh") {
          lang = "en";
        }
      }
      if (i18n.language == lang) return;
      i18n.changeLanguage(lang);
    }
  }, []);// 第二引数は空にすれば初回のみ
}